// const API_URL = 'https://10.220.0.10:3443/api/';
const API_URL = 'https://kompas.tadmar.pl:3443/api/';

export const environment: any = {
  production: false,
  guide: 'https://kompas.tadmar.pl:3443/guide.pdf',
  api: {
    url: API_URL,
    auth: {
      login_check: API_URL + 'auth/login_check',
      change: API_URL + 'auth/change',
      permission: API_URL + 'permission',
      refresh: API_URL + 'token/refresh',
      forgot: API_URL + 'auth/forgot',
    },
    users: {
      current_user: API_URL + 'users/current-user',
      list: API_URL + 'users',
      create: API_URL + 'users/user',
      detail: API_URL + 'users/%s',
      edit: API_URL + 'users/%s',
      remove: API_URL + 'users/%s',
    },
    dictionary: {
      contractors: API_URL + 'dictionary/contractors',
      roles: API_URL + 'dictionary/roles',
      regions: API_URL + 'dictionary/regions',
      investments: API_URL + 'dictionary/investments',
      traders: API_URL + 'dictionary/traders',
    },
    investments: {
      list: API_URL + 'investments',
      detail: API_URL + 'investments/%s',
      history: API_URL + 'investments/%s/history',
      notes: API_URL + 'investments/%s/comments',
      notesDelete: API_URL + 'investments/%s/comments/%s',
      reject: API_URL + 'investments/%s/rejected',
      remove: API_URL + 'investments/%s/remove',
      paused: API_URL + 'investments/%s/paused',
      ended: API_URL + 'investments/%s/ended',
      restore: API_URL + 'investments/%s/restore',
      rejectDepartment: API_URL + 'investments/%s/department/rejected',
      rejectDepartmentDo: API_URL + 'investments/%s/do',
      assignDepartment: API_URL + 'investments/%s/department',
      assignTrader: API_URL + 'investments/%s/trader',
      acceptTrader: API_URL + 'investments/%s/trader/accepted',
      rejectTrader: API_URL + 'investments/%s/trader/rejected',
      correctData: API_URL + 'investments/%s/address/correct',
      assortmentGroup: API_URL + 'investments/%s/assortment-groups/%s',
      assortmentGroupTraderAssign: API_URL + 'investments/%s/assortment-groups/%s/trader',
      assortmentGroupTraderAccept: API_URL + 'investments/%s/assortment-groups/%s/trader/accepted',
      assortmentGroupTraderReject: API_URL + 'investments/%s/assortment-groups/%s/trader/rejected',
      userHistory: API_URL + 'investments/user/history',
      creator: {
        primaryData: API_URL + 'investments/%s/address',
        demands: API_URL + 'investments/%s/demands',
        assignDemand: API_URL + 'investments/%s/demands/%s',
        removeDemand: API_URL + 'investments/%s/demands/%s',
        files: API_URL + 'investments/%s/files',
        media: API_URL + 'investments/%s/files',
        editMedia: API_URL + 'investments/%s/files/%s',
        removeMedia: API_URL + 'investments/%s/files/%s',
        generalConstructor: API_URL + 'investments/%s/general-contractor',
        subcontractors: API_URL + 'investments/%s/subcontractors',
        assortmentGroups: API_URL + 'investments/%s/assortment-groups',
        assortmentGroupMessage: API_URL + 'investments/%s/assortment-groups/%s/message',
        createAssortmentGroupSubcontractor: API_URL + 'investments/%s/assortment-groups/%s/contractor',
        editAssortmentGroupSubcontractor: API_URL + 'investments/%s/assortment-groups/%s/contractor/%s',
        removeAssortmentGroupSubcontractor: API_URL + 'investments/%s/assortment-groups/%s/contractor/%s',
        editSubcontractor: API_URL + 'investments/%s/subcontractors/%s',
      },
    },
    timetable: {
      list: API_URL + 'timetable',
      create: API_URL + 'timetable',
      remove: API_URL + 'timetable/%s',
      users: API_URL + 'timetable/users',
    },
    contacts: {
      create: API_URL + 'contractor/%s/contact',
      edit: API_URL + 'contractor/%s/contact/%s',
      remove: API_URL + 'contractor/%s/contact/%s',
    },
    settings: {
      list: API_URL + 'setting',
      edit: API_URL + 'setting/%s',
    },
    statistics: API_URL + 'statistics/%s',
    notification: {
      list: API_URL + 'notification',
      deactivateAll: API_URL + 'notification/deactivate/all'
    }
  }
};
